<template>
	<div>
		<div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices_bons') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0">{{ $t('invoice.see_stats') }} <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="invoiceStat">
                <div class="row pt-4">
                   <b-table striped hover :items="stat" :fields="fields_stat"></b-table>
                </div>
            </b-collapse>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>
		<div class="row">
		    <div class="col-auto ml-auto d-flex">
		        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
		            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
		        </a>
		    </div>
		</div>
		<div class="box">
			<CustomTable
				id_table="bons"
				:items="bons"
				:busy.sync="table_busy"
				primaryKey="invoicedetails_id"
				:hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
				:rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
				ref="bonTable"
			>
                <template v-slot:[`custom-slot-cell(invoicedetails_tiershorsepartpart)`]="{ data }">
					{{ data.invoicedetails_tiershorsepartpart }}%
				</template>
            </CustomTable>
		</div>

		<template v-if="has_doublons">
			<div class="row">
			    <div class="col">
					<h2>{{ $t("invoice.titre_bons_doublons") }}</h2>
			    </div>
			    <div class="col-auto ml-auto d-flex">
			        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>
			    </div>
			</div>
			<div class="box">
				<CustomTable
					id_table="bons_doublons"
					:items="bons_doublons"
					:busy.sync="table_busy"
					primaryKey="invoicedetails_id"
					:hide_if_empty="true"
					:hrefsRoutes="config_table_hrefs"
					:rawColumns="rawColumnNames"
					ref="bonDoublonsTable"
				/>
			</div>
		</template>

		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.facturer") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="checkIfFirstInvoiceMonth" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="form-group">
								<label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
								<e-datepicker v-model="date"></e-datepicker>
								<small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
							</div>

							<div class="form-group" v-if="date > today && can_display_renew">
								<label for="date_automatique">{{ $t('invoice.renouvellement_facture') }}</label>
								<e-select
									v-model="renew"
									id="code"
									track-by="label"
									label="label"
									:placeholder="labelTitleTemplateRenew"
									:deselectLabel="deselectLabel"
									:selectedLabel="selectedLabel"
									:options="renew_options"
									:searchable="true"
									:allow-empty="false"
									:show-labels="false"
								>
								</e-select>
							</div>

							<div class="row">
								<div class="col-6 mb-2">
									<label class="mb-1" for="articles_ht">{{ $t('invoice.ref_client') }}</label>
									<input type="text" class="form-control" v-model="ref_client">
								</div>
								<div class="col-6 mb-2">
									<label class="mb-1" for="articles_ht">{{ $t('invoice.dossier_interne') }}</label>
									<input type="text" class="form-control" v-model="dossier_interne">
								</div>
							</div>

							<div class="form-group">
								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="send_email"
											:unchecked-value="false"
										>
										{{ $t('invoice.send_invoice_by_email') }}
										</b-form-checkbox>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="many_duedate"
											>
											{{ $t('invoice.multi_echeance') }}
										</b-form-checkbox>
									</div>
								</div>

								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="change_model"
											>
											{{ $t('invoice.changer_modele_facture') }}
										</b-form-checkbox>
									</div>
								</div>
								<div class="row">
									<div class="col">
										<b-form-checkbox
											v-model="change_rib"
											>
											{{ $t('invoice.changer_rib_default') }}
										</b-form-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mb-3" v-for="(invoice, invoice_key) in params_to_send.invoices" :key="'invoice_'+invoice_key">
						<div class="row border-top pt-3">
							<div class="col">
								<label for="duedate_date">{{ invoice.group_by_author_tiers }} {{ $t('invoice.invoice_balance') }} :</label>
								<br>
								<span> {{ amount[invoice.author_id + '_' + invoice.tiers_id].invoice_ttc_formatted }} </span>
							</div>
						</div>
						<div class="row" v-show="many_duedate">
							<div class="col">
								<div class="form-group">
									<template v-for="(duedate, duedate_key) in invoice.duedates" >
										<div class="row" :key="'duedate_'+duedate_key">
											<div class="col">
												<e-datepicker v-model="duedate.date" :class="{ 'is-invalid': errors && errors.indexOf('Erreur date duedate ' + duedate.date) > -1 }"></e-datepicker>
											</div>
											<div class="col">
												<input class="form-control" type="text" v-model="duedate.amount" :class="{ 'is-invalid': errors && errors.indexOf('Erreur somme duedate ' + duedate.amount) > -1 }">
											</div>
										</div>
									</template>
									<a href="#" @click.prevent="add_duedate(invoice)">{{ $t('global.ajouter') }}</a>
								</div>
							</div>
						</div>

						<div class="row" v-if="send_email_ready">
							<div class="col">
								<div class="form-group">
									<label for="modeltype_label">{{ $t('model.modeltype_label') }}</label>
									<e-select
										v-if="show_model_selector"
										v-model="invoice.template"
										id="model_id"
										track-by="model_label"
										label="model_label"
										:placeholder="labelTitleTemplate"
										:deselectLabel="deselectLabel"
										:selectedLabel="selectedLabel"
										:options="templates[invoice.author_id]"
										:searchable="true"
										:allow-empty="false"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
										<template slot="noOptions">{{ $t('global.list_empty') }}</template>
									</e-select>
								</div>
							</div>
						</div>

						<div class="row" v-if="change_model_ready">
							<div class="col">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_modele_specifique') }}</label>
									<e-select
										v-model="invoice.model"
										id="search_compte"
										track-by="model_label"
										label="model_label"
										:placeholder="getTrad('invoice.choisir_un_modele_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="model[invoice.author_id]"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingModel"
										:show-labels="false"
									>
									</e-select>
								</div>
							</div>
						</div>

						<div class="row" v-if="change_rib_ready">
							<div class="col">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_rib_specifique') }}</label>
									<e-select
										v-model="invoice.rib"
										id="search_compte"
										track-by="checkdepositconfiguration_id"
										label="checkdepositconfiguration_iban"
										:placeholder="getTrad('invoice.choisir_un_rib_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="ribs[invoice.author_id]"
										:searchable="true"
										:allow-empty="true"
										:loading="isLoadingRib"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
										<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
									</e-select>
								</div>
							</div>
						</div>
					</div>

					
				</form>

				<div class="col-8 m-auto">
					<b-button block pill variant="primary" @click="checkIfFirstInvoiceMonth"><font-awesome-icon v-if="processing || processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalLigneVierge" no-close-on-backdrop hide-footer>
			<template v-slot:modal-title>

				<template v-if="ajouter">
					{{ $t("action.ajout_ligne_vierge") }}
				</template>

				<template v-if="modifier">
					{{ $t("action.modifier_ligne_vierge") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<LigneVierge ref="ligne" :invoicedetails_id="invoicedetails_id" :bons="bons" :processing.sync="processing" :preselect_infos="preselect_infos" :ready.sync="ready" :options="options"></LigneVierge>

				<div class="col-8 m-auto">
					<template v-if="ready && invoicedetails_id">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier_et_nouveau") }}</b-button>
					</template>
					<template v-else-if="ready">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter_et_nouveau") }}</b-button>
					</template>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalCancel" hide-footer>
			<template v-slot:modal-title>
				{{ $t("modal.invoice.bons.suppression") }}
			</template>

			<p class="text-center">{{ $t("modal.invoice.bons.confirmation_suppression") }}</p>

			<div class="row">
				<div class="col-12 d-flex justify-content-center">
					<b-button variant="primary" @click="onCancel(true)" v-if="is_cancelation">{{ $t("action.repasser_a_valider") }}</b-button>
					<b-button variant="primary" class="mx-2" @click="onCancel">{{ $t("action.abandonner") }}</b-button>
					<b-button variant="secondary" @click="$refs.modalCancel.hide()">{{ $t("global.close") }}</b-button>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalChangeTiers" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.change_tiers") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<form @submit.prevent="checkFormChangeTiers" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="mb-2">
								<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
								<e-select
									v-model="change_tiers"
									track-by="id"
									label="rs"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabel"
									:options="tiers"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingTiers"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('Erreur no tiers') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.rs }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>

							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button v-if="ready && invoicedetails_id" block pill variant="primary" @click="onSubmitChangeTiers"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal ref="confirmation_first_invoice" hide-footer>
			<template v-slot:modal-title>
				{{ $t("invoice.title_first_invoice_of_month") }}
			</template>
			<div class="row justify-content-around">
				<div class="col">
					{{ $t("invoice.first_invoice_of_month") }}
					
					<b-button class="mt-3 rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("global.continuer") }}</b-button>
					<b-button class="mt-3 rounded-pill" block @click.prevent="closeFirstInvoiceModal" variant="primary">{{ $t("global.annuler") }}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'

	import Common from '@/assets/js/common.js'
	import TableAction from "@/mixins/TableAction.js"
	import Invoice from "@/mixins/Invoice.js"
	import Tiers from "@/mixins/Tiers.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Model from "@/mixins/Model.js"
	import Accounting from "@/mixins/Accounting.js"
	import Contract from "@/mixins/Contract.js"

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import _uniq from 'lodash/uniq'
import ESelect from '../../../groomy-root/src/components/Utils/ESelect.vue'

	export default {
		name: "list",
		mixins: [Config, TableAction, Invoice, Navigation, Tiers, ShutterContract, Shutter, Model, Accounting, Contract],
		props: {
			options: {
				type: Object,
				default: () => ({
					type: 'Invoice',
					id: null,
				})
			}
		},
		data () {
			return {
				bons: null,
				bons_doublons: null,
				table_busy: true,
				first_invoice: false,
				modal_loaded: false,
				invoicedetails_id: null,
				date: new Date(),
				today: new Date(),
				is_cancelation: true,
				processing_check: false,
				send_email: false,
				form_message: '',
				params_to_send : [],
				errors : [],
				config_duedate : [],
				amount : {},
				processing: false,
				ready: false,
				entity_id: null,
				horse_id: null,
				tiers_id: null,
				ajouter: true,
				modifier: false,
				many_duedate: false,
				has_doublons: false,
				change_model: false,
				isLoadingModel: false,
				isLoadingRib: false,
				copy_duedate: null,
				change_rib: false,
				can_display_renew: false,
				send_email_ready: false,
				change_model_ready: false,
				change_rib_ready: false,
				dedoublonner: false,
				cancel_invoicedetails_ids: null,
				preselect_infos: {},
				change_tiers: null,
				use_rib_stallions: true,
				ref_client: null,
				dossier_interne: null,
				renew: {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				},
				renew_options: [
					{
						"code": 'null',
						"label": this.getTrad("invoice.renew.no")
					},
					{
						"code": 'mensuel',
						"label": this.getTrad("invoice.renew.mensuel")
					},
					{
						"code": 'annuel',
						"label": this.getTrad("invoice.renew.annuel")
					},
				],
				ribs: [],
				model: [],
				tiers: [],
				isLoadingTiers: false,
				labelTitleTiers: this.getTrad("tiers.rechercher"),
				extern_slot_columns: ['invoicedetails_tiershorsepartpart'],
				selectedLabel: this.getTrad("global.selected_label"),
				labelTitleTemplate: this.getTrad("horse.search_doc"),
				labelTitleTemplateRenew: this.getTrad("invoice.search_renew"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				cache: {
					model: []
				},
				templates: [],
				template: null,
				recalcul_duedate: true,
				ribs_from_stallions: [],
				new_invoices_tab: [],
				details_selected: [],
				show_model_selector: true,
				rawColumnNames: ['horse.horse_nom','invoicedetails_ht'],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToAddInvoice': (params) => {
						this.addInvoiceForm(params.invoicedetails_id)
					},
					'TableAction::goToAddInvoiceDetail': (params) => {
						this.addInvoiceDetailForm(params.invoicedetails_id)
					},
					'TableAction::goToCancelInvoiceDetail': (params) => {
						this.is_cancelation = true
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = false
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToDedoublonnerInvoiceDetail': (params) => {
						this.is_cancelation = false
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = true
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id, true)
					},
					'TableAction::goToPreviewInvoice': (params) => {
						this.previewInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToChangeTiersBaf': (params) => {
						this.changeTiersBaf(params)
					},
					'TableAction::goToReorganizeInvoiceDetails': (params) => {
						let bons = this.bons.filter(bon => params.indexOf(bon.invoicedetails_id) > -1 )
						let grouped_bons = bons.map((elem) => {
							return elem.invoicedetails_author + "_" + elem.invoicedetails_tiers
						})
						grouped_bons = _uniq(grouped_bons)
						if(grouped_bons.length > 1)
						{
							this.failureToast('toast.reorganize_only_one_tiers')
							return false
						}

						this.$router.push({ name: 'invoiceDetailOrder', params: {invoicedetails_ids: params.join(',')}})
					},
					'TableAction::goToCopyInvoiceDetail': (params) => {
						this.copyInvoiceDetail(params)
					},
					'TableAction::goToRecheckVat': (params) => {
						this.recheckVat(params)
					}
				},
				stats_loaded: true,
                stat: [],
                fields_stat: [
					{key: 'tiers', label: this.getTrad('monte.reservation.client'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true},
                ]
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.loadBons().then(async () => {
					this.compareLoadingMethods()
				})
			},
			async loadBons() {
				this.table_busy = true
				this.has_doublons = false
                const bons = await this.loadAllBons(this.options)
                this.bons = await this.formatBons(bons)

                const bons_doublons = await this.loadAllBons({
                	type: 'Doublons',
                	id: null
                })
                this.bons_doublons = await this.formatBons(bons_doublons)
                if(this.bons_doublons.length > 0)
                {
                	this.has_doublons = true
                }

				this.loadStats()
                
                this.table_busy = false
            },
            async formatBons(bons) {
				if(!bons) {
					return []
				}

				for (var i = 0; i < bons.length; i++) {
					bons[i].invoicedetails_ht = await this.priceFormat(bons[i].invoicedetails_ht, bons[i].author.tiers_currency,true)
					bons[i].invoicedetails_htunit = await this.priceFormat(bons[i].invoicedetails_htunit, bons[i].author.tiers_currency,true)

					if(bons[i].horse != null)
					{
						bons[i].href = "horseFiche"
						bons[i].params = { horse_id: bons[i].horse.horse_id }
					}

					if(bons[i].invoicedetails_horsepensionperiode != null)
					{
						bons[i].articles.articles_label += " - " + bons[i].invoicedetails_label
					}

					bons[i].group_by_author_tiers = bons[i].author.tiers_rs + " / " + bons[i].tiers.tiers_rs + " (" + this.getTrad("compta.form." + bons[i].vat_code_tiers.toLowerCase()) + ")"
					bons[i].customClasses={
                        invoicedetails_ht:"text-right"
					}
				}

				return bons
			},
			async addInvoiceForm(invoicedetails_ids)
			{
				this.modal_loaded = false
				this.$refs['modal'].show()
				

				this.details_selected = this.bons.filter(bons => invoicedetails_ids.includes(bons.invoicedetails_id))
				this.can_display_renew = false

				const splited_invoice_details = invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
                const author_tiers = []
                const tiers_tab = []
                const authors = []
				let nb_ligne_vierge = 0
				this.ribs_from_stallions = []
                
				for (var i = 0; i < splited_invoice_details.length; i++) {
                    const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(bons_selected.invoicedetails_actes == null && bons_selected.invoicedetails_contract == null && bons_selected.invoicedetails_horsepensionperiode == null) {
						nb_ligne_vierge++
					}

					if(author_tiers.indexOf(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers) === -1) {
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers,
							"group_by_author_tiers": bons_selected.group_by_author_tiers
						})

						tiers_tab.push(bons_selected.tiers)
						author_tiers.push(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers)
					}

					if(bons_selected.contract && bons_selected.contract.contract_config && bons_selected.contract.contract_config.contractconfig_configuration != null) {
						if(!this.ribs_from_stallions[bons_selected.group_by_author_tiers]) {
							this.ribs_from_stallions[bons_selected.group_by_author_tiers] = []
						}

						this.ribs_from_stallions[bons_selected.group_by_author_tiers].push(bons_selected.contract.contract_config.contractconfig_configuration)
					}
					else{
						this.use_rib_stallions = false
					}
				}

				if(nb_ligne_vierge == splited_invoice_details.length) {
					this.can_display_renew = true
				}

				this.params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": invoicedetails_ids
                }
                
                // On vérifie que les tiers sont notifiables
                const tiers_unotifiable = tiers_tab.filter(tier => {
                    return !tier.tiers_mail
                })

                if(tiers_unotifiable.length === 1) {
                    this.infoToast("invoice.tier_non_notifiable", [tiers_unotifiable[0].tiers_rs])
                }
                else if(tiers_unotifiable.length > 1) {
                    const delimiter = ', '
                    let tiers_rs = tiers_unotifiable.map(tier => tier.tiers_rs).join(delimiter)
                    const index = tiers_rs.lastIndexOf(delimiter)
                    tiers_rs = tiers_rs.substring(0, index) + ' '+this.getTrad('global.et')+' ' + tiers_rs.substring(index + delimiter.length)

                    this.infoToast("invoice.tiers_non_notifiable", [tiers_rs])
                }

                const tiers_ids = tiers_tab.map(tier => tier.tiers_id)
				this.config_duedate = await this.getConfigDuedate(tiers_ids)

				const amount = await this.calculateInvoice(this.params_to_send)
				this.amount = await this.formatAmount(amount)

				await this.add_duedate()

				this.modal_loaded = true
			},

			async formatAmount(amount) {
				return amount
			},

			async getNewDate(tiers_id, date) {
				return await this.getNextDateDuedate(tiers_id, date)
			},

			async calculDuedateDate(use_prev = false){

				this.new_invoices_tab = []

				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let author_id = this.params_to_send.invoices[i].author_id
					let tiers_id = this.params_to_send.invoices[i].tiers_id
					let key = author_id + "_" + tiers_id

					let current_date = this.date

					//Je regarde si je dois prendre la date précédente pour calculer les échéances à venir
					if(use_prev)
					{
						if(this.params_to_send.invoices[i].duedates != undefined)
						{
							let all_amount = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)

							//Je parcours les echeances pour calculer la date
							for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
							{

								let date = null
								let current = null
								let amount_float = 0

								if(j == 0)
								{
									if(this.params_to_send.invoices[i].duedates[j] != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)
									}
								}
								else
								{
									if(this.params_to_send.invoices[i].duedates[j].date != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], this.params_to_send.invoices[i].duedates[j - 1].date)
									}
								}

								//Si j'ai une valeur de renseignée, je la reprend
								if(this.params_to_send.invoices[i].duedates[j].amount != undefined)
								{
									amount_float = parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
								}

								if((j + 1) == this.params_to_send.invoices[i].duedates.length)
								{
									amount_float = all_amount
								}

								this.params_to_send.invoices[i].duedates[j] = {
									'date': current,
									'amount': (amount_float / 100).toString().toFloat(),
									'amount_float': amount_float,
									'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
								}
								all_amount -= amount_float

								if(all_amount < 0)
								{
									all_amount = 0
								}
							}
						}
					}
					else
					{
						let date = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)

						let current = new Date(date);
						let amount_float = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						this.params_to_send.invoices[i].duedates = [{
							'date': current,
							'amount': this.amount[key].invoice_ttc,
							'amount_float': amount_float,
							'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						}]
					}
					this.new_invoices_tab.push(this.params_to_send.invoices[i])
				}

				this.$set(this.params_to_send, 'invoices', this.new_invoices_tab)
			},


			async add_duedate(tab_invoice){
				if(tab_invoice != undefined)
				{
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						//J'ajoute une date là ou j'ai choisi de cliquer sur ajouter
						if(this.params_to_send.invoices[i].group_by_author_tiers == tab_invoice.group_by_author_tiers)
						{
							let current = new Date(this.date);
							const key = this.params_to_send.invoices[i].author_id + "_" + this.params_to_send.invoices[i].tiers_id

							this.params_to_send.invoices[i].duedates.push({
								'date': undefined,
								'amount': "0",
								'amount_float': 0,
								'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
							})
						}
					}
					this.calculDuedateDate(true)
				}
				else
				{
					this.calculDuedateDate()
				}

			},

			addInvoiceDetailForm(invoicedetails_id) {
				this.modal_loaded = false
				this.preselect_infos = {}
				if(invoicedetails_id == "")
				{
					this.modifier = false
					this.ajouter = true
				}
				else
				{
					this.modifier = true
					this.ajouter = false
				}
				this.invoicedetails_id = invoicedetails_id

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			copyInvoiceDetail(invoicedetails) {

				this.modal_loaded = false

				this.modifier = false
				this.ajouter = true
				this.preselect_infos = {
					entity: invoicedetails.author,
					tiers: invoicedetails.tiers,
					horse: invoicedetails.horse,
					article: invoicedetails.articles,
					invoice_details: invoicedetails,
				}

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			async previewInvoiceDetail(invoicedetails_ids) {
				this.modal_loaded = false

				const splited_invoice_details = invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
				const tiers = []
				const authors = []
				for (var i = 0; i < splited_invoice_details.length; i++) {

					const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(authors.indexOf(bons_selected.invoicedetails_author) === -1 || tiers.indexOf(bons_selected.invoicedetails_tiers) === -1)
					{
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers,
						})

						tiers.push(bons_selected.invoicedetails_tiers)
						authors.push(bons_selected.invoicedetails_author)
					}
				}

				let params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": invoicedetails_ids
				}

				let res = await this.previewInvoice(params_to_send)

				if(res) {
					EventBus.$emit('TableAction::stopSpin')
				}

				this.modal_loaded = true
			},

			checkIfFirstInvoiceMonth: async function() {
				this.processing_check = true
				const elems = await this.checkFirstInvoiceMonth(this.date.toDateInputValue(), this.params_to_send.invoicedetails_ids)
				
				this.processing_check = false
				if(elems.nb == 0) {
					this.$refs['confirmation_first_invoice'].show()
				}
				else {
					await this.checkForm()
				}
			},

			closeFirstInvoiceModal: async function(){
				this.$refs['confirmation_first_invoice'].hide()
			},

			checkForm: async function(){
				
				this.$refs['confirmation_first_invoice'].hide()

				if(!this.processing)
				{
					this.processing = true
					this.errors = []

					if(this.date == null)
					{
						this.errors.push("Erreur date null")
					}

					if(!Date.parse(this.date))
					{
						this.errors.push("Erreur wrong date")
					}

					//Je vais regarder que mes dates d'échéances soient supérieurs ou égales à ma date de facturation, et regarder le montant
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						let somme = 0

						//Si j'ai qu'un seul rib etalon de selectionné pour une entité/tiers, et que je n'ai pas choisi manuellement mon rib
						if(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers] != undefined
							&& _uniq(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers]).length == 1
							&& (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)
							&& this.use_rib_stallions
						) {
							this.params_to_send.invoices[i].rib = {
								"checkdepositconfiguration_id": this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers][0]
							}
						}

						for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
						{
							if(j == 0)
							{
								somme = this.params_to_send.invoices[i].duedates[j].total_invoice
							}

							if(!Date.parse(this.params_to_send.invoices[i].duedates[j].date) || this.params_to_send.invoices[i].duedates[j].date < this.date)
							{
								this.errors.push("Erreur date duedate " + this.params_to_send.invoices[i].duedates[j].date)
							}

							somme -= parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
						}
						if(somme != 0)
						{
							this.errors.push("Erreur somme duedate " + this.params_to_send.invoices[i].duedates[0].amount)
						}
                    }

					if(this.errors.length == 0)
					{
						this.params_to_send.date = this.date;
						this.params_to_send.send_mail = this.send_email;

						let renew = null
						if(this.date > this.today) {
							if(this.renew.code != 'null') {
								renew = this.renew.code
							}
						}

						this.params_to_send.renew = renew
						this.params_to_send.ref_client = this.ref_client
						this.params_to_send.dossier_interne = this.dossier_interne

						const result = await this.createInvoice(this.params_to_send);
						if(result) {
							const details_contract = this.details_selected.filter(details => details.invoicedetails_contract)
							const contract_ids = _uniq(details_contract.map(details => details.invoicedetails_contract))
		                    await this.$sync.force(true)
		                	ContractCleaner.inst().onMutation(contract_ids, ['articles'])
		                	this.updateContract(contract_ids)
							this.$refs['modal'].hide()
							this.$router.push({name: 'invoiceList'})
						} else {
							this.form_message = "error.LEP"
						}
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}

					this.processing = false
				}

			},

			async onCancel(go_valider) {
				this.$refs.modalCancel.hide();
				if(go_valider === true) {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, false)
				}
			},

			async cancelInvoiceDetail(invoicedetails_ids, dedoublonner, send_a_valider){
				let params_to_send = {
					invoicedetails_ids: invoicedetails_ids,
					send_a_valider: send_a_valider
				}

				let result = null
				if(dedoublonner != undefined && dedoublonner === true) {
					result = await this.dedoublonnerInvoiceDetails(params_to_send)
				}
				else{
					result = await this.deleteInvoiceDetails(params_to_send)
				}


				if(result != null) {
					this.$refs.bonTable.resetCachedItems()

					if(this.$refs.bonDoublonsTable) {
						this.$refs.bonDoublonsTable.resetCachedItems()
					}

					this.successToast()
					this.loadBons()

					if(this.options.type == "Contract"){
						//Je force la synchro pour reload la table contract_avenant_article et ensuite je vais recharger les "a valider"
						EventBus.$emit('TableAction::waitingTableAValider', true)
						await this.$sync.force(true, true)
	                    ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                    this.updateContract([this.options.id])
	                    EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
						EventBus.$emit('TableAction::reloadAValider', true)
					}
				}
			},

			async recheckVat(params){
				let result = await this.getRecheckVat(params.invoicedetails_id.split(','))

				if(result != null) {
					this.$refs.bonTable.resetCachedItems()
					this.successToast()
					this.loadBons()
				}
			},

			async onSubmit(open_with_copy = false) {
				this.processing = true
				let invoice_details = await this.$refs.ligne.checkForm()

				if(invoice_details !== false)
				{
					this.$refs["modalLigneVierge"].hide()
					this.successToast()
					this.loadBons()
					if(this.options.type == "Contract") {
                    	await this.$sync.force(true)
	                	ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                	this.updateContract([this.options.id])
					}

					if(open_with_copy === true) {
						this.invoicedetails_id = null
						//Dans le cas d'ajout de ligne, on me retourne un tableau de ligne, du coup je prend la 1ere
						if(Array.isArray(invoice_details.retour)) {
							this.copyInvoiceDetail(invoice_details.retour[0])
						}
						else {
							this.copyInvoiceDetail(invoice_details.retour)
						}
					}
				}
				this.processing = false
				this.ready = true
			},
			reset_copy_duedate(){

				this.copy_duedate = null
			},

			pension_checkall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						//Je vais regarder toutes les pensions id qui vont ensembles sur le même cheval et même tiers pour toutes les facturer en même temps
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode
						
						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode)

						this.$refs.bonTable.manual_check(all_same_pensions)
					}
				}
				
			},

			pension_decheckall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode

						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode) 
						this.$refs.bonTable.manual_decheck(all_same_pensions)
					}
				}
				
			},

			async load_model(){

				this.change_model_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)

					let models = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'])
					models = models.filter(model => (model.type.modeltype_template === 'facture'))

					this.model[this.params_to_send.invoices[i].author_id] = models
				}

				this.change_model_ready = true
			},

			async load_model_notification(){

				this.send_email_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)
					const templates = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'], 'invoice_notification')

					const model_default = {
						model_default: false,
						model_id: 0,
						model_label: this.getTrad('invoice.model_mail_default'),
					}

					templates.push(model_default)
					this.templates[this.params_to_send.invoices[i].author_id] = templates
					this.params_to_send.invoices[i].template = model_default
				}

				this.send_email_ready = true
			},

			async load_rib(){
				this.change_rib_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)

					let ribs = await this.getCheckDepositConfigListe(result.accountingplan_id)

					this.ribs[this.params_to_send.invoices[i].author_id] = ribs
				}

				this.change_rib_ready = true
			},

			async changeTiersBaf(invoicedetails_ids){
				this.$refs.modalChangeTiers.show()
				this.ready = false
				this.modal_loaded = false
				this.isLoadingTiers = true
				this.invoicedetails_id = invoicedetails_ids

				let tiers = await this.loadTiers()
				this.tiers = []
                for (let i = 0; i < tiers.length; i++) {
					this.tiers.push({
						id: tiers[i].tiers_id,
						rs: tiers[i].tiers_formatted,
					})
				}

				this.ready = true
				this.modal_loaded = true
				this.isLoadingTiers = false
			},

			async onSubmitChangeTiers(){

				if(!this.processing)
				{
					this.processing = true
					this.errors = []
					if(!this.invoicedetails_id)
					{
						this.errors.push("lignes")
					}

					if(!this.change_tiers)
					{
						this.errors.push("Erreur no tiers")
					}
					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let result = null
					result = await this.setChangeTiers(this.invoicedetails_id, this.change_tiers.id)
					if(result != null)
					{
						this.successToast();
						this.$refs.modalChangeTiers.hide()
						this.processing = false
						
						await this.loadBons();
					}
					else{
						this.processing = false
						this.failureToast();
					}
					this.change_tiers = null
				}

			},

			async loadStats() {
                this.stats_loaded = false
                this.stat = []

                if(this.bons.length === 0) {
                    this.stats_loaded = true
                    return false
                }

				const invoicedetails_ids = this.bons.map(invoice_details => invoice_details.invoicedetails_id)

                const stats = await this.loadInvoiceBonsStat(invoicedetails_ids)

                await Common.asyncForEach(stats, async (stat) => {
                    this.stat.push({
                        tiers: stat.tiers,
                        total_ht: await this.priceFormat(stat.total_ht, 'EUR')
                    })
                })

                this.stats_loaded = true
            },
			async compareLoadingMethods() {
				const bons = await this.loadAllBonsv2(this.options)
                const bons_v2 = await this.formatBons(bons)

				if(this.bons.length != bons_v2.length) {
					this.sendErrorSlack(this.bons.length, bons_v2.length)
				}
			}
		},
		computed: {
			duedates: function(){
				return this.params_to_send.invoices
			}
		},
		watch: {
			date(val, prev)
			{
				if(this.recalcul_duedate)
				{
					this.add_duedate()
					this.reset_copy_duedate()
				}
				this.calculDuedateDate()
			},
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate)
					{
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null)
					{
						if(JSON.stringify(val) != this.copy_duedate)
						{
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}
					}

				},
				deep: true
			},
			many_duedate(val, prev){

				if(val === false) {
					this.add_duedate()
					this.reset_copy_duedate()
				}
			},
			change_model(val, prev){
				if(val === true) {
					this.load_model()
				}
				else{
					this.change_model_ready = false
				}

			},
			send_email(val, prev){
				if(val === true) {
					this.send_email_ready = true
					this.load_model_notification()
				}
				else{
					this.send_email_ready = false
				}
			},
			change_rib(val, prev){
				if(val === true) {
					this.load_rib()
				}
				else{
					this.change_rib_ready = false
				}
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			LigneVierge: () => import('@/components/Invoice/LigneVierge'),
			Reorganize: () => import('@/components/Invoice/Reorganize')
		}
	}
</script>
